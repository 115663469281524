@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DotGothic16&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


/* Apply the Inter font globally */
body {
  font-family: 'Inter', sans-serif;
}

html, body {
  background-color: #11111b;
}

.cursor-glow {
  position: fixed;
  inset: 0;
  pointer-events: none;
  height: 100vh;
  z-index: 9999;
}

/* Change text selection color */
::selection {
  background-color: #363a4f; /* Use the custom color defined in Tailwind config */
  color: white; /* Optional: Change the text color within the selection */
}

/* Change text selection color */
::selection {
  background-color: #363a4f; /* Use the custom color defined in Tailwind config */
  color: white; /* Optional: Change the text color within the selection */
}

/* Sticky behavior for larger screens */
@media (min-width: 768px) {
  .md\\:sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

/* Base styles for the experience card */
.experience-card {
  cursor: pointer;
  position: relative; /* Ensure the pseudo-elements are positioned relative to the card */
  transition: box-shadow 0.3s ease, background-color 0.3s ease, border 0.3s ease;
  border-radius: 5px; /* Adjust the border-radius as needed */
  overflow: hidden; /* Ensure the pseudo-elements don't overflow */
}

/* Pseudo-element for the border effect */
.experience-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px; /* Match the border-radius of the card */
  border: 1px solid transparent; /* Initial border is transparent */
  transition: border-color 0.3s ease;
  pointer-events: none; /* Ensure the pseudo-element doesn't interfere with mouse events */
}

/* Hover effect */
.experience-card:hover {
  background: rgba(255, 255, 255, 0.02);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.arrow {
  display: inline-block;
  transition: transform 0.1s ease;
  transform: rotate(-45deg);
  font-size: 13px;
}

.experience-card:hover .arrow {
  transform: translate(2px, -2px) rotate(-45deg); /* Adjust the values as needed */
}

.experience-card:hover .exp-title {
  color: #f5a97f;
}

.exp-title {
  transition: color 0.3s ease;
}

.experience-card:hover::before {
  border-color: rgba(255, 255, 255, 0.3); /* Border color on hover */
}

/* Dimming effect */
.experience-card.dimmed {
  transition: opacity 0.15s ease;
  opacity: 0.6; /* Adjust the opacity as needed */
}

.experience-card.nondimmed {
  transition: opacity 0.15s ease;
  opacity: 1; /* Adjust the opacity as needed */
}




.area{
  background: #11111b;  
  width: 100%;
  height:100vh;
  position: fixed;
}

.circles{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li{
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.034);
  animation: animate 25s linear infinite;
  bottom: -150px;
  
}

.circles li:nth-child(1){
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.circles li:nth-child(2){
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3){
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4){
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5){
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6){
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7){
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8){
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9){
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10){
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}



@keyframes animate {

  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }

  100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }

}

navigation-menu {
  position: fixed;
  top: 50%;
}

.navigation-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navigation-menu li {
  margin-bottom: 1rem;
}


.navigation-menu a {
  text-decoration: none;
  color: #8a8a8a;
  position: relative;
  display: inline-block;
  padding-left: 3rem;
  transition: padding-left 0.2s;
}

.navigation-menu a:hover {
  transition: padding-left 0.2s;
  padding-left: 5rem;
}

.navigation-menu a::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2rem;
  height: 1px;
  background-color: #8a8a8a;
  transition: width 0.2s;
}

.navigation-menu a:hover::before,
.navigation-menu a:focus::before,
.navigation-menu li.active a::before {
  width: 4rem;
}

.navigation-menu a:hover,
.navigation-menu a:focus,
.navigation-menu li.active a {
  color: #ffffff;
  transition: padding-left 0.2s;
  padding-left: 5rem;
}

.document-links {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.link {
  text-decoration: none;
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  color: "gray-100";
  transition: color 0.25s ease-out;
}

.link:hover {
  color: #a6da95;
  transition: color 0.25s ease-out;
}

.link:hover .arrow {
  transform: translate(2px, -2px) rotate(-45deg); /* Adjust the values as needed */
}